<template>
  <base-section id="salvation" class="pt-10">
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <base-title title="What the Bible says about you..." class="text-center" />
        </v-col>
      </v-row>
      <v-container v-for="section in sections" :key="section.title" class="pb-10">
        <base-section-heading :icon="section.icon" :title="section.title" />
        <v-row justify="center">
          <v-col v-for="card in section.cards" :key="card.title" cols="12" md="4">
            <base-info-card v-bind="card" />
          </v-col>
        </v-row>
        <v-row v-if="section.display === 'church'">
          <v-col v-if="church" class="text-center">
            <base-subtitle title="You are welcome at" class="text-center" />
            <base-title :title="church.name" class="text-center" />
            <base-subtitle :title="church.address.street" class="text-center" />
            <base-subtitle v-if="church_address" :title="church_address" class="text-center" />
            <base-subtitle :title="church.service_time" class="text-center mt-5" />
            <a align-self="center" target="_blank" @click="visitChurchLink">
              <base-btn class="mt-5">
                <span>Find Church on Google</span>
              </base-btn>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col class="text-center">
          <feedback value="salvation" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
// Extensions

import churches from "@/lib/churches";
import sections from "@/lib/salvation_verses";

export default {
  name: "Salvation",
  components: {
    Feedback: () => import("@/views/sections/Feedback"),
  },
  data() {
    return {
      sections: sections
    };
  },
  computed: {
    church() {
      return this.$route.params.church
        ? churches[this.$route.params.church]
        : null;
    },
    church_address() {
      return this.church
        ? `${this.church.address.suburb} ${this.church.address.state} ${this.church.address.postcode}`
        : null;
    }
  },
  methods: {
    visitChurchLink() {
      this.$gtag.event(this.$route.params.church, {
        event_category: "church_link",
        event_label: this.$route.params.church,
        value: this.church.maps_url
      });
      location.href = this.church.maps_url
    }
  },
};
</script>
