const churches = {
  belvoir_baptist: {
    name: "Belvoir St Baptist Church",
    website: "https://bsbc.live",
    maps_url: "https://bit.ly/3KAecv0",
    address: {
      street: "12 Belvoir Street - corner, Buckingham Street",
      suburb: "Surry Hills",
      state: "NSW",
      postcode: "2010"
    },
    service_time: "Sunday 10:30am-11:30am, 5:30pm-6:30pm"
  },
  glenorie_mission: {
    name: "Glenorie Mission Church",
    website: "http://glenoriemission.org/",
    maps_url: "https://bit.ly/3rKXpge",
    address: {
      street: "1409 Old Northern Rd",
      suburb: "Glenorie",
      state: "NSW",
      postcode: "2157"
    },
    service_time: "Sunday at 10am"
  }
};

export default churches;
