const sections = [
  {
    title: "God Loves You",
    icon: "mdi-mother-heart",
    cards: [
      {
        subtitle: "John 3:16",
        text:
          '"For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life.'
      },
      {
        subtitle: "Romans 5:8",
        text:
          "but God shows his love for us in that while we were still sinners, Christ died for us."
      }
    ]
  },
  {
    title: "All Are Sinners",
    icon: "mdi-snake",
    cards: [
      {
        subtitle: "Romans 3:23",
        text: "for all have sinned and fall short of the glory of God,"
      },
      {
        subtitle: "Romans 3:10",
        text: 'as it is written: "None is righteous, no, not one;'
      }
    ]
  },
  {
    title: "God's Remedy For Sin",
    icon: "mdi-hand-heart",
    cards: [
      {
        subtitle: "Romans 6:23",
        text:
          "For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord."
      },
      {
        subtitle: "John 1:12",
        text:
          "But to all who did receive him, who believed in his name, he gave the right to become children of God,"
      },
      {
        subtitle: "1 Corinthians 15:3,4",
        text:
          "For I delivered to you as of first importance what I also received: that Christ died for our sins in accordance with the Scriptures, that he was buried, that he was raised on the third day in accordance with the Scriptures,"
      }
    ]
  },
  {
    title: "All May Be Saved Now",
    icon: "mdi-hand-front-right",
    cards: [
      {
        subtitle: "Revelation 3:20",
        text:
          "Behold, I stand at the door and knock. If anyone hears my voice and opens the door, I will come in to him and eat with him, and he with me."
      },
      {
        subtitle: "Romans 10:13",
        text: "For “everyone who calls on the name of the Lord will be saved.”"
      }
    ]
  },
  {
    title: "My Decision",
    icon: "mdi-cross",
    cards: [
      {
        title: "Pray this prayer to receive Christ as your saviour",
        text:
          "Confessing to God that I am a sinner, and believing that the Lord Jesus Christ died for my sins on the cross and was raised for my justification, I do now receive and confess him as my personal Saviour."
      }
    ]
  },
  {
    title: "Seeking A Church",
    display: "church",
    icon: "mdi-church",
    cards: [
      {
        text:
          "After making your decision to receive Christ, we encourage you to prayerfully seek a local church congregation, or assembly that will assist you in growing as a new Christian by the clear teaching of the Bible."
      },
      {
        subtitle: "2 Peter 3:18",
        text:
          "But grow in the grace and knowledge of our Lord and Savior Jesus Christ. To him be the glory both now and to the day of eternity. Amen."
      }
    ]
  },
  {
    title: "Assurance As A Believer",
    icon: "mdi-hands-pray",
    cards: [
      {
        subtitle: "Romans 10:9",
        text:
          "because, if you confess with your mouth that Jesus is Lord and believe in your heart that God raised him from the dead, you will be saved."
      },
      {
        subtitle: "John 5:24",
        text:
          "Truly, truly, I say to you, whoever hears my word and believes him who sent me has eternal life. He does not come into judgment, but has passed from death to life."
      },
      {
        subtitle: "John 20:31",
        text:
          "but these are written so that you may believe that Jesus is the Christ, the Son of God, and that by believing you may have life in his name."
      }
    ]
  }
];

export default sections;
